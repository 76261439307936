// import React, { Component } from "react";
// import axios from "axios";
// import "./config";

// export default class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       email: "",
//       password: "",
//     };
//     console.log(global.config.i18n.welcome.en);

//     global.config.i18n.welcome.en = "Welcome to React";
//   }

//   handleInputChange = (event) => {
//     const { value, name } = event.target;
//     this.setState({
//       [name]: value,
//     });
//   };

//   onSubmit = (event) => {
//     event.preventDefault();
//     axios
//       .post("http://localhost:8080/api/authenticate", {
//         email: this.state.email,
//         password: this.state.password,
//       })

//       .then((res) => {
//         alert("success");
//         localStorage.setItem("authToken", res.data.response);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   render() {
//     return (
//       <form onSubmit={this.onSubmit}>
//         <h1>Login Below!</h1>
//         <input
//           type="email"
//           name="email"
//           placeholder="Enter email"
//           value={this.state.email}
//           onChange={this.handleInputChange}
//           required
//         />
//         <input
//           type="password"
//           name="password"
//           placeholder="Enter password"
//           value={this.state.password}
//           onChange={this.handleInputChange}
//           required
//         />
//         <input type="submit" value="Submit" />
//       </form>
//     );
//   }
// }
import React,{useContext} from 'react'

import authContext from "./context";

 const Login = () => {
  const { setAuthenticated } = useContext(authContext);
  const handleLogin = () => setAuthenticated(true);
  const handleLogout = () => setAuthenticated(false);

  return (
    <div>
       <button onClick={handleLogin}>login</button>
      <button onClick={handleLogout}>logout</button>
      </div>
  )
}
export default Login