import React, { createContext, useState, useContext, useMemo } from "react";
import { Link, Route, Switch } from "react-router-dom";
import withAuth from "./withAuth";
import Home from "./Home";
import Secret from "./Secret";
import Login from "./Login";
import authContext from "./context";

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  return (
    <div>
       <authContext.Provider value={{ authenticated, setAuthenticated }}>
        <ul>
          <li>
            <Link to="/secret">Secret</Link>
          </li>
          <li>
            <Link to="/login">Login</Link>
          </li>
        </ul>

        <Switch>
          <Route path="/secret" component={Secret} />
          <Route path="/login" component={Login} />
        </Switch>
        </authContext.Provider>
    </div>
  );
};
export default App;
