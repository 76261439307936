// import React, { Component } from 'react';
// import axios from 'axios'

// export default class Secret extends Component {
//   constructor() {
//     super();
//     this.state = {
//       message: 'Loading...'
//     }
//   }

//   componentDidMount() {
//     axios.get('http://localhost:8080/api/secret')

//     .then(res => {
//       console.log(res.data)
//     }
//     )
//     .catch(err => {
//       console.log(err.response.data)
//     });
//   }

//   render() {
//     return (
//       <div>
//         <h1>Secsdret</h1>
//         <p>{this.state.message}</p>
//       </div>
//     );
//   }
// }
import React,{useContext} from 'react'
import authContext from "./context";

 const Login = () => {

  const theme = useContext(authContext);
  console.log(theme);
  return <span>{theme}</span>;
}
export default Login